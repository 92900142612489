import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import { AuthStorage } from '@/utils/storage/AuthStorage'
import MAlert from '@/utils/MAlert'
import { defaultTo, toNumber } from 'lodash-es'
import {
  callKeepDepartmentUsers,
  callSaveDepartmentUsers,
  callToAddFailCount,
  callToCheckLoginUser,
  callToGetMenuList,
  callToGetOtpSecretKey,
  callToLogin,
  callToResetOtp,
  callToSignUp,
  callToUpdateUserPassword,
} from '@/services/auth/apis'
import { Utils } from '@/utils/Utils'
import { MenuStorage } from '@/utils/storage/MenuStorage'
import { AuthUtils } from '@/utils/AuthUtils'
import { MenuParentModel } from '@/services/auth/menu.types'
import { TOTP, Secret } from 'otpauth'
import { OTP_OPTIONS } from '@/utils/consts'

const PATH = ['auth']
const GET_MENU_LIST = [...PATH, 'GET_MENU_LIST']

export const useLogin = () => {
  return useMutation({
    mutationFn: callToLogin,
  })
}

export const useResetOtp = () => {
  return useMutation({
    mutationFn: callToResetOtp,
  })
}

export const useGetOtpSecretKey = () => {
  return useMutation({
    mutationFn: async () => {
      const secret = new Secret({ size: 20 })
      const base32Secret = secret.base32

      const { SPR_NM = '', email = '' } = AuthStorage.user.get()
      const { otpKey = '' } = await callToGetOtpSecretKey({
        email,
        sprNm: SPR_NM,
        secret: base32Secret,
      })

      AuthStorage.user.set({
        ...AuthStorage.user.get(),
        OTP_KEY: otpKey,
      })

      const totp = new TOTP({ ...OTP_OPTIONS, secret: base32Secret, label: email })
      return totp.toString()
    },
  })
}

export const useCheckUser = () => {
  return useMutation({
    mutationFn: callToCheckLoginUser,
  })
}

export const useSignUp = () => {
  return useMutation({
    mutationFn: callToSignUp,
  })
}

export const useUpdateUserPassword = () => {
  return useMutation({
    mutationFn: callToUpdateUserPassword,
  })
}

export const useCheckValidOtp = () => {
  return useMutation({
    mutationFn: async (otpToken: string) => {
      const { email, OTP_KEY: otpKey } = AuthStorage.user.get()
      if (!email) {
        MAlert.show('로그인 먼저 해주세요')
        return false
      }

      if (!otpKey) {
        MAlert.show('Google OTP (Two Factor)를 설정해주세요.')
        return false
      }

      const totp = new TOTP({ ...OTP_OPTIONS, secret: otpKey, label: email })
      const delta = totp.validate({ token: otpToken, window: 1 })
      const isValid = delta !== null
      let failCount = 0

      if (!isValid) {
        try {
          const response = await callToAddFailCount(email)

          if (response.type) {
            failCount = defaultTo(
              toNumber(
                (response.message || '')
                  .match(/\((.*)\)/)
                  ?.pop()
                  ?.replace('회 실패', '') || '0',
              ),
              0,
            )
          } else {
            failCount = 5
          }
        } catch (e) {
          MAlert.show(Utils.getApiErrMessage(e, '계정 확인에 문제가 생겼습니다.'))
          AuthUtils.logout()
        }
      }

      return {
        isValid,
        failCount,
      }
    },
  })
}

export const useKeepDepartmentUserList = () => {
  return useMutation({
    mutationFn: callKeepDepartmentUsers,
  })
}

export const useSaveDepartmentUserList = () => {
  return useMutation({
    mutationFn: callSaveDepartmentUsers,
  })
}

export const useGetMenuList = () => {
  return useQuery({
    queryKey: GET_MENU_LIST,
    enabled: false,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const userInfo = AuthStorage.user.get()
      const { AUTH_CD = '', company = '', email = '' } = userInfo

      const menuList = await callToGetMenuList({ AUTH_CD, SPR_NM: company, email })
      MenuStorage.menu.set(menuList)

      return menuList
    },
    select: (data) =>
      data.map((parent) => ({
        menuCode: parent.MENU_CD.toLowerCase(),
        menuName: parent.MENU_NM,
        menuItem:
          parent.MENU_ITEM?.map((child) => ({
            menuCode: child.MENU_CD.toLowerCase(),
            menuName: child.MENU_NM,
            link: `/${parent.MENU_CD.toLowerCase()}/${child.MENU_CD.toLowerCase()}`,
          })) ?? [],
      })) as MenuParentModel[],
    meta: {
      noErrorMessage: true,
    },
  })
}
