import { LoginRes } from '@/services/auth/login.types'
import { AuthStorage } from '@/utils/storage/AuthStorage'
import { Utils } from '@/utils/Utils'
import { PATHS } from '@/utils/routes/paths'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

// Reset OTP, LOGIN, AuthChecker 에서 사용
const useLoginSuccessFlow = () => {
  const navigate = useNavigate()

  const onCheckAuthFlow = useCallback((onSuccess?: () => void) => {
    const isOtpUnauthenticated = AuthStorage.otpUnauthenticated.get()
    const userInfo = AuthStorage.user.get()

    // OTP 인증 필요한 경우
    if (isOtpUnauthenticated) {
      navigate(PATHS['auth.otp'])
    }
    // 임시 비밀번호 사용, 비밀번호 변경 90일이 지난 경우
    if (userInfo.temporaryPassword || userInfo.modifyFlag) {
      navigate(PATHS['auth.password'])
    }
    // [관리자] 부서 유저 확인한지 30일이 넘은 경우
    else if (userInfo.deptMng) {
      navigate(PATHS['auth.department-users'])
    }
    // 모두 통과한 경우
    else if (onSuccess) {
      onSuccess()
    }
  }, [])

  const onSaveLoginInfoToSession = useCallback((response: LoginRes, onAfter?: () => void) => {
    AuthStorage.auth.set(response.token)
    AuthStorage.user.setByLogin(response)

    // 운영환경에서만 OTP 처리
    // if (Utils.isProd()) AuthStorage.otpUnauthenticated.set(true)
    AuthStorage.otpUnauthenticated.set(true)

    // 이후 처리
    if (onAfter) onAfter()
  }, [])

  return {
    onCheckAuthFlow,
    onSaveLoginInfoToSession,
  }
}

export default useLoginSuccessFlow
